import 'fomantic-ui-css/semantic.min.css';

import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createHead } from '@unhead/vue';
import { Amplify } from 'aws-amplify';
import { createApp } from 'vue';
import FomanticUI from 'vue-fomantic-ui';
import VueGtag from 'vue-gtag';
import { VueRecaptchaPlugin } from 'vue-recaptcha';

import App from './App.vue';
import { router } from './router.ts';
import {
  isViteProduction,
  setupEnvironmentFromUrl,
  VERSION,
} from './services/local/utils';

const env = setupEnvironmentFromUrl();

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: env.authUserPoolId,
      userPoolClientId: env.authClientId,
      loginWith: {
        oauth: {
          domain: env.authWebDomain,
          scopes: ['email', 'openid'],
          redirectSignIn: [`${env.webBaseUrl}/admin/sign-in`],
          redirectSignOut: [`${env.webBaseUrl}/admin`],
          responseType: 'code',
        },
      },
    },
  },
});

// `head` is useful for initializing the Vue recaptcha.
const head = createHead();
const app = createApp(App)
  .use(head)
  .use(router)
  .use(FomanticUI)
  .use(createGtm({ id: env.gtmId, debug: !isViteProduction }))
  .use(VueGtag, { config: { id: env.gtagId } })
  .use(VueRecaptchaPlugin, { v2SiteKey: env.recaptchaSiteKey })
  .use(VueQueryPlugin);

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: env.dsn,
    environment: env.stage,
    release: VERSION,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    denyUrls: ['www.googletagmanager.com'],
    tracesSampleRate: 0.1,
    beforeSend: (event, hint) => {
      const error = hint.originalException;

      // Filter out exceptions coming from Google Tag Manager.
      if (error instanceof Error && error.stack?.includes('/gtm.js?id=')) {
        return null;
      }

      return event;
    },
  });
}

app.mount('#app');
